import React from 'react'
import { Card } from 'react-bootstrap'
function PortfolioCard({ title, description, externalLink }) {
    const handleCardClick = () => {
      window.location.href = externalLink
    }
  
    return (
      <Card className='card-portfolio' onClick={handleCardClick} bg="secondary">
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <Card.Text>{description}</Card.Text>
        </Card.Body>
      </Card>
    )
  }
  export default PortfolioCard