import React from 'react'
import './App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ProgressBar } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import { faGithub, faGitlab, faWhatsapp, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import foto from "./images/foto.jpeg"
import 'bootstrap/dist/css/bootstrap.min.css'
import PortfolioCard from "./Component/Card"

function subtractYears() {
  return Math.abs(new Date().getFullYear() - 2009)
}
function App() {
  const scrollToSection = (id) => {
    const element = document.getElementById(id)
    element.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <Container className="p-3">
      <div className="App">
        <header className="App-header">
          <nav>
            <ul className="header-ul">
              <li onClick={() => scrollToSection('about')}>About</li>
              <li onClick={() => scrollToSection('resume')}>Resume</li>
              <li onClick={() => scrollToSection('portfolio')}>Portfolio</li>
              <li onClick={() => scrollToSection('contact')}>Contact</li>
            </ul>
          </nav>
        </header>
        <main>
          <section id="about" className="section">
            <h2>About Me</h2>
            <div className='about-section'>
              <img alt="about-me" src={foto} className="profile-photo" />
              <p>{`I am a Senior Software Engineer with over 
                ${subtractYears()} years of experience in full-stack development, using various programming languages and
                cloud services to create stable, secure, and fast web applications.

                My core competencies include Node.js, Python, PHP, React.js,
Docker, Kubernetes, AWS, Elasticsearch, Apache Airflow, Jenkins,
and GitHub actions. I am passionate about software development
and technology innovation, and I enjoy learning new tools and
methodologies to drive problem-solving and development efficiency.`}</p>
            </div>
            <h2>Honors-Awards</h2>
            <div>
            Bronze medal in the Brazilian Mathematics Olympiad
            </div>
          </section>
          <section id="resume" className="section">
            <h2 >Education</h2>
            <div>
              <h3>Ampli</h3>
              <p >Bachelor's degree Software Engineering</p>
              <h3>UFMG</h3>
              <p >Associate's degree, Mathematics</p>
              <h3>CEFET-MG</h3>
              <p >Associate's degree, Electrical and Electronics Engineering</p>
            </div>
            <div className="resume-content">
              <h2 className='skills'>Skills</h2>
              <ProgressBar className="ProgressBar" striped variant="success" now={90} label="NodeJs" />
              <ProgressBar className="ProgressBar" striped variant="info" now={90} label="ReactJs" />
              <ProgressBar className="ProgressBar" striped variant="warning" now={80} label="NextJs" />
              <ProgressBar className="ProgressBar" striped variant="danger" now={90} label="PHP" />
              <ProgressBar className="ProgressBar" striped variant="succes" now={80} label="Python" />
            </div>
          </section>
          <section id="portfolio" className="section">
            <PortfolioCard
              className="col-md-8"
              title="Challenges Projects"
              description="It's a single page web app to shows some Challenges that I had solved"
              externalLink="https://challenges.sistemaglobal.com.br/login"
            />
            <div className="row mt-2">
              <p> To test it, you can use demo credentials</p>
              <p>user: demoweather</p>
              <p>password:Weather(@#@)</p>
            </div>
          </section>
          <section id="contact" className="section">
            <h2>Contact Me</h2>
            <p>Lucas Dias</p>
            <p>Software Developer</p>
            <p>+55 31 994672020</p>
            <p>diasnasc.lucas@gmail.com</p>
            <>
              {new Date().getFullYear()}
            </>
          </section>
        </main>
        <footer>
          <div className="social-icons">
            <a href="https://www.linkedin.com/in/dnlucas/" target="_blank" rel="noopener noreferrer">
            </a>
            <a href="https://github.com/lucasndias" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faGithub} size="2x" />
            </a>
            <a href="mailto:diasnasc.lucas@gmail.com">
              <FontAwesomeIcon icon={faEnvelope} size="2x" />
            </a>
            <a href="https://whatsa.me/5531994672020/?t=Hi">
              <FontAwesomeIcon icon={faWhatsapp} size="2x" />
            </a>
            <a href="https://www.linkedin.com/in/dnlucas/">
              <FontAwesomeIcon icon={faLinkedin} size="2x" />
            </a>

          </div>
        </footer>
      </div>
    </Container>
  )
}

export default App
